import React from 'react'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
    {
        title: '主页',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'IDO',
        path: '/ido',
        icon: <FaIcons.FaCartPlus />,
        cName: 'nav-text'
    },
    {
        title: '邀请',
        path: '/invitation',
        icon: <IoIcons.IoIosPaper />,
        cName: 'nav-text'
    },
    {
        title: '代幣機制',
        path: '/mechanism',
        icon: <FaIcons.FaCartPlus />,
        cName: 'nav-text'
    },
]
