import React from 'react'

const Bottom = () => {
  return (
    <div style={{background: 'transparent', height:'5vh', width:'100vw', color: 'transparent'}}>
      100
    </div>
  )
}

export default Bottom
