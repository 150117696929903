import React from 'react'
import Countdown from '../components/Countdown'
import Number from '../components/Number'
import HomeImage from '../image/tuYuanYuan3.png'
import IDOImage from '../image/TuYuanYuanIDO2.png'
function Home() {
  const gotoIDOPage = () => {

  }
  return (
    <div className='home' style={{
      position: 'absolute',
      top: '80px',
      height: '90vh',
      width: '100vw',
      zIndex: '1',
      overflowY: 'scroll'
    }}>
      <div className='homeBackground'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          filter: 'blur(5px)',
          zIndex: -1,
        }} />

      <img src={HomeImage} alt="Home Image" style={{
        width: '100vw',
        marginTop: '10px',
        borderTop: '1px solid #efc75e',
        // borderRadius: '20px'
      }} />
      <Countdown />
      <a href="./ido" >
        <img src={IDOImage} alt="IDO Image" style={{
          borderBottom: '1px solid #efc75e',
          width: '100vw',
        }} onClick={gotoIDOPage} />
      </a>
    </div>
  )
}

export default Home
