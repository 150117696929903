import React from 'react'
import IDOImage from '../image/TuYuanYuanIDO2.png'
import Number from '../components/Number'
import Countdown from '../components/Countdown'
import Bottom from '../components/Bottom'
const IDO = () => {
    const handlePassBack = (value) => {
        console.log(value)
        alert(`參與金額為 ${value} USDT`)
    }
    return (
        <div className='IDO' style={{
            position: 'absolute',
            top: '80px',
            height: '100vh',
            width: '100vw',
            zIndex: '1',
            overflowY: 'scroll',
            borderBottom: '1px solid #efc75e',
        }}>
            <div className='homeBackground'
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    filter: 'blur(5px)',
                    zIndex: -1,
                }} />
            <Countdown />
            <Number passBack={handlePassBack} />
            <Bottom />
        </div>
    )
}

export default IDO
