import React from 'react'
import mechanismImage from '../image/Mechanism.png'
const Mechanism = () => {
    return (
        <div className='Mechanism' style={{
            position: 'absolute',
            top: '80px',
            height: '100vh',
            width: '100vw',
            zIndex: '1',
            overflowY: 'scroll',
            borderBottom: '1px solid #efc75e',
        }}>
            <div className='homeBackground'
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    filter: 'blur(5px)',
                    zIndex: -1,
                }} />
            <img src={mechanismImage} alt="Mechanism Image" style={{
                width:'100vw',
            }}/>
        </div>
    )
}

export default Mechanism
