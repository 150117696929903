import './App.css';
import { useState } from 'react';
import { ethers } from 'ethers';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Reports from './Pages/Reports';
import Products from './Pages/Products';
import WalletConnect from './components/WalletConnect';
import IDO from './Pages/IDO';
import Invitation from './Pages/Invitation';
import Mechanism from './Pages/Mechanism';

let currentAccount = null;
// 初加载刷新
function App() {

  const [defaultAccount,setDefaultAccount] = useState(null)

  const handleAccountChange = (value) => {
    if (value === defaultAccount) return;
    if (value === null) return;
    setDefaultAccount(value)
  }
  return (

    <div className="App" id="bg">
      <WalletConnect defaultAccountChange={handleAccountChange} />
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/ido' element={<IDO />} />
          <Route path='/invitation' element={<Invitation defaultAccount={defaultAccount}/>} />
          <Route path='/mechanism' element={<Mechanism />} />
        </Routes>
      </Router>
    </div>

  );
}

export default App;
